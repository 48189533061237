import { useAppContext } from 'components/Context/AppContext';
import { BookingZone, Button, InputField, SelectFieldAdditionalItem, SelectFieldDuration } from 'components/Global';
import SelectFieldCar from 'components/Global/SelectFieldCar';
import SelectFieldDate from 'components/Global/SelectFieldDate';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';
import { addDays, format } from 'date-fns';
import { fetchSummaryOrder } from 'features/orders/actions';
// import { getVehiclesByFilter } from 'features/vehicle/action';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CarChair } from '../../../../../../icons/car-chair-icon.svg';
import { ReactComponent as Calendar } from '../../../../../../icons/calendar-icon.svg';
import SelectFieldTimeWithDriver from 'components/Global/SelectFieldTimeWithDriver';
import SelectFieldShuttle from 'components/Global/SelectFieldShuttle';
// import TextAreaInput from 'components/Global/TextArea';
import { checkEmptyObject, setCurrency } from 'utils/functionality';

// OVERTIME
export const overtimeWithDriver = [
  {
    id: 'ot0',
    name: 'Tidak Ada Overtime',
    value: 0,
  },
  {
    id: 'ot1',
    name: '1 Jam',
    value: 1,
  },
  {
    id: 'ot2',
    name: '2 Jam',
    value: 2,
  },
  {
    id: 'ot3',
    name: '3 Jam',
    value: 3,
  },
  {
    id: 'ot4',
    name: '4 Jam',
    value: 4,
  },
  {
    id: 'ot5',
    name: '5 Jam',
    value: 5,
  },
  {
    id: 'ot6',
    name: '6 Jam',
    value: 6,
  },
  {
    id: 'ot7',
    name: '7 Jam',
    value: 7,
  },
  {
    id: 'ot8',
    name: '8 Jam',
    value: 8,
  },
  {
    id: 'ot9',
    name: '9 Jam',
    value: 9,
  },
  {
    id: 'ot10',
    name: '10 Jam',
    value: 10,
  },
  {
    id: 'ot11',
    name: '11 Jam',
    value: 11,
  },
  {
    id: 'ot12',
    name: '12 Jam',
    value: 12,
  },
];

// PAYMENT TYPE
const paymentType = [
  {
    id: 'pyt1',
    name: 'Pembayaran Full',
    value: 'FULL',
  },
  {
    id: 'pyt2',
    name: 'Pembayaran DP',
    value: 'HALF',
  },
];

const WithDriverForm = ({
  prevPage,
  nextPage,
  orderData,
  setOrderData,
  allVehicle,
  selectedCar,
  setSelectedCar,
  vehicleInput,
  setVehicleInput,
  isOrderConfirmation,
  startRentDate,
  setStartRentDate,
  durationRent,
  setDurationRent,
  // driver,
  // setDriver,
  selectedOvertime,
  // setSelectedOvertime,
  selectedPaymentType,
  setSelectedPaymentType,
  dayDuration,
  setDayDuration,
  allZonesList,
  rentalLocationId,
  setRentalLocationId,
  selectedAdditionalItem,
  setSelectedAdditionalItem,
  // descriptionAdditionalItem,
  // setDescriptionAdditionalItem,
}) => {
  const dispatch = useDispatch();

  const { showToast } = useAppContext();

  // MAPPED LOCAL STATE
  const [mappedPaymentType, setMappedPaymentType] = useState(paymentType);

  // const { data: dataVehicle } = useSelector((state) => state.vehicle);

  // ADDITIONAL INPUT SEARCH VALUE
  const [additionalSearchInput, setAdditionalSearchInput] = useState('');

  const { data: rentalLocationData } = useSelector((state) => state.rentalLocation);
  const { data: rentalServicesData } = useSelector((state) => state.services);
  const { data: summaryOrderData } = useSelector((state) => state.summaryOrder);
  const { data: additionalData } = useSelector((state) => state.addon);

  // SET END BOOKING TIME
  // useEffect(() => {
  //   if (!orderData.order_detail.start_booking_time) return;

  //   setOrderData({
  //     ...orderData,
  //     order_detail: {
  //       ...orderData.order_detail,
  //       end_booking_time: '',
  //     },
  //   });
  // }, [orderData.order_detail.start_booking_time]);

  // OVERTIME
  useEffect(() => {
    if (!selectedOvertime) return;

    const overtimeValue = overtimeWithDriver.find((item) => item.id === selectedOvertime).value;
    setOrderData({ ...orderData, over_time: overtimeValue });
  }, [selectedOvertime]);

  // PAYMENT TYPE
  useEffect(() => {
    if (!selectedPaymentType) return;

    const paymentTypeValue = paymentType.find((item) => item.id === selectedPaymentType).value;
    setOrderData({ ...orderData, type: paymentTypeValue });
  }, [selectedPaymentType]);

  useEffect(() => {
    if (
      orderData.order_detail.start_booking_date === '' ||
      orderData.order_detail.start_booking_time === '' ||
      orderData.order_detail.end_booking_date === '' ||
      orderData.order_detail.end_booking_time === '' ||
      !orderData.order_detail.vehicle_id ||
      orderData.order_booking_zone?.length > 0 ||
      dayDuration?.length > 0 ||
      isOrderConfirmation
    )
      return;
    const subServiceWithoutDriver = rentalServicesData
      ?.find((item) => item.name === 'Sewa Mobil')
      ?.sub_services?.find((item) => item.name === 'Daily')
      ?.facilities?.find((item) => item.name === 'With Driver');

    let mappingAdditionalItem = [];

    if (selectedAdditionalItem) {
      for (let addItionalChild of selectedAdditionalItem) {
        const additionalObj = {
          id: addItionalChild.additional.id,
          varieties: [
            {
              id: addItionalChild.additional.varieties[0].id,
              quantity: addItionalChild.total,
            },
          ],
        };

        mappingAdditionalItem.push(additionalObj);
      }
    }

    dispatch(
      fetchSummaryOrder({
        end_booking_date: orderData.order_detail.end_booking_date,
        end_booking_time: dayDuration
          ? dayDuration[dayDuration?.length - 1]?.booking_end_time
          : orderData.order_detail.end_booking_time,
        start_booking_date: orderData.order_detail.start_booking_date,
        start_booking_time: orderData.order_detail.start_booking_time,
        order_type_id: orderData.order_type_id,
        vehicle_id: orderData.order_detail.vehicle_id || 0,
        without_driver: orderData.order_detail.without_driver ? 1 : 0,
        location_id: rentalLocationId?.id,
        sub_services_id: subServiceWithoutDriver?.id,
        addons: mappingAdditionalItem.length === 0 ? undefined : mappingAdditionalItem,
      }),
    );
  }, [
    rentalServicesData,
    orderData.order_detail.end_booking_date,
    orderData.order_detail.end_booking_time,
    orderData.order_detail.start_booking_date,
    orderData.order_detail.start_booking_time,
    orderData.order_detail.vehicle_id,
    orderData.order_booking_zone,
    dayDuration,
    rentalLocationId,
    selectedAdditionalItem,
  ]);

  useEffect(() => {
    if (!Object.keys(summaryOrderData).length) return;

    if (summaryOrderData.total_dp > 0) {
      setMappedPaymentType((prev) =>
        prev.map((item) =>
          item.id == 'pyt2'
            ? {
                ...item,
                name: `${
                  item.name.includes('%')
                    ? item.name
                    : summaryOrderData?.formula_percentage?.value
                    ? `${item.name} ${summaryOrderData.formula_percentage.value}%`
                    : item.name
                }`,
              }
            : item,
        ),
      );
    } else if (summaryOrderData.total_dp === 0) {
      setMappedPaymentType((prev) => prev.filter((item) => item.id !== 'pyt2'));
      setSelectedPaymentType('pyt1');
    }
  }, [summaryOrderData]);

  // INTERSECTION FUNCTION OF VEHICLES
  // const intersectionAction = () => {
  //   if (orderData.order_detail.vehicle_id !== '') return;

  //   const payload = {
  //     locationRental: rentalLocationId.id,
  //     startTrip: `${orderData.order_detail.start_booking_date} ${orderData.order_detail.start_booking_time}`,
  //     endTrip: `${orderData.order_detail.end_booking_date} ${orderData.order_detail.end_booking_time}`,
  //     supportDriver: !orderData.order_detail.without_driver,
  //     customOrder: true,
  //     page: dataVehicle.pagination.page + 1,
  //     limit: 0,
  //   };

  //   // if last page then just return nothing
  //   if (dataVehicle.pagination.page >= dataVehicle.pagination.last_page) return;

  //   // if not last page fetch
  //   dispatch(getVehiclesByFilter(payload));
  // };

  // FUNCTION TO PREVENT SCROLLING ON INPUT NUMBER TYPE
  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current
    // function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const getSubserviceIdWithDriver = () => {
    const subServiceWithoutDriver = rentalServicesData
      ?.find((item) => item.name === 'Sewa Mobil')
      ?.sub_services?.find((item) => item.name === 'Daily')
      ?.facilities?.find((item) => item.name === 'With Driver');

    return subServiceWithoutDriver?.id;
  };

  // FUNCTION SUBMIT
  const submitHandler = async () => {
    // some validation
    const validStartBookingDate = orderData.order_detail.start_booking_date !== '';
    // const validEndBookingDate = orderData.order_detail.end_booking_date !== '';
    const validDurationRent = durationRent !== 0;
    const validStartBookingTime = orderData.order_detail.start_booking_time !== '';
    const validEndBookingTime = orderData.order_detail.end_booking_time !== '';
    const validVehicle = orderData.order_detail.vehicle_id !== '';
    // const validDriver = driver !== 0;
    const validPaymentType = orderData.type !== '';
    const validBookingZone =
      orderData.order_detail.booking_zones && orderData.order_detail.booking_zones.length === durationRent;
    const validPassenger = orderData.order_detail.passenger_number;
    const validDayDuration = dayDuration && dayDuration.length === durationRent;
    const validBaggage = isNaN(orderData.order_detail.baggage) || orderData.order_detail.baggage === '';

    // fill overtime_duration if there is not one, map if there is
    setDayDuration((prev) =>
      prev.map((item) => (item?.overtime_duration === undefined ? { ...item, overtime_duration: 0 } : item)),
    );

    // check if all input are valid
    if (!validStartBookingDate) {
      return showToast({ type: 'error', message: 'Pilih Tanggal Mulai' });
    } else if (!validDurationRent) {
      return showToast({ type: 'error', message: 'Pilih Durasi Sewa' });
    } else if (!validStartBookingTime) {
      return showToast({ type: 'error', message: 'Pilih Jam Mulai' });
    } else if (!validEndBookingTime) {
      return showToast({ type: 'error', message: 'Pilih Jam Selesai' });
    } else if (!validPaymentType) {
      return showToast({ type: 'error', message: 'Pilih Tipe Pembayaran' });
    } else if (!validVehicle) {
      return showToast({ type: 'error', message: 'Pilih Mobil' });
    } else if (!validBookingZone) {
      return showToast({ type: 'error', message: 'Pilih Area Zona' });
    }
    // else if (!validDriver) {
    //   return showToast({ type: 'error', message: 'Pilih Driver' });
    // }
    else if (!validPassenger) {
      return showToast({ type: 'error', message: 'Pilih Jumlah Penumpang' });
    } else if (!validDayDuration) {
      return showToast({ type: 'error', message: 'Pilih Jam Mulai dan Jam Selesai' });
    } else if (validBaggage) {
      return showToast({ type: 'error', message: 'Masukan Banyak Koper yang dibawa' });
    }

    let mappingAdditionalItem = [];

    if (selectedAdditionalItem) {
      for (let addItionalChild of selectedAdditionalItem) {
        const additionalObj = {
          id: addItionalChild.additional.id,
          varieties: [
            {
              id: addItionalChild.additional.varieties[0].id,
              quantity: addItionalChild.total,
            },
          ],
        };

        mappingAdditionalItem.push(additionalObj);
      }
    }

    // GET ORDER SUMMARY
    const payload = {
      end_booking_date: orderData.order_detail.end_booking_date,
      end_booking_time: dayDuration
        ? dayDuration[dayDuration?.length - 1]?.booking_end_time
        : orderData.order_detail.end_booking_time,
      order_type_id: orderData.order_type_id,
      start_booking_date: orderData.order_detail.start_booking_date,
      start_booking_time: orderData.order_detail.start_booking_time,
      vehicle_id: orderData.order_detail.vehicle_id,
      without_driver: orderData.order_detail.without_driver ? 1 : 0,
      deposit: orderData.deposit,
      violations: orderData.order_violations.reduce((acc, cur) => acc + cur.cost, 0),
      // overtime: orderData.over_time,
      order_booking_zone: orderData.order_detail.booking_zones.map((item, idx) => ({
        day: idx + 1,
        pick_up_zone_id: item.pickup_zone_id,
        drop_off_zone_id: item.drop_off_zone_id,
        driving_zone_id: item.driving_zone_id,
        booking_start_time: dayDuration[idx].booking_start_time,
        booking_end_time: dayDuration[idx].booking_end_time,
        overtime_duration: overtimeWithDriver.find((ovt) => ovt.id === dayDuration[idx].overtime_duration)?.value || 0,
        is_driver_stay_overnight: item.is_driver_stay_overnight,
      })),
      pasengger_number: orderData.order_detail.passenger_number,
      location_id: rentalLocationId?.id,
      sub_services_id: getSubserviceIdWithDriver(),
      addons: mappingAdditionalItem.length === 0 ? undefined : mappingAdditionalItem,
    };

    try {
      const response = await dispatch(fetchSummaryOrder(payload));
      if (response.meta?.requestStatus === 'fulfilled') {
        nextPage();
        return;
      }
      showToast({ type: 'error', message: 'Terjadi Kesalahan' });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      showToast({ type: 'error', message: 'Terjadi Kesalahan' });
    }

    nextPage();
  };

  const changeRentalLocationHandler = (item) => {
    if (item.id === rentalLocationId.id) return;
    setRentalLocationId(item);

    setSelectedAdditionalItem(undefined);
    setSelectedCar(undefined);
    setVehicleInput('');
    setOrderData({
      ...orderData,
      order_detail: {
        ...orderData.order_detail,
        vehicle_id: '',
        passenger_number: 0,
        booking_zones: orderData.order_detail.booking_zones
          ? orderData.order_detail.booking_zones.map((zone) => ({
              date: zone.date,
              pickup_zone_id: 0,
              detail_pickup_location: '',
              pickup_zone_price: 0,
              drop_off_zone_id: 0,
              detail_drop_off_location: '',
              drop_off_zone_price: 0,
              driving_zone_id: 0,
              driving_zone_price: 0,
              booking_start_time: zone.booking_start_time,
              booking_end_time: zone.booking_end_time,
              overtime_duration: zone.overtime_duration,
              total_price: 0,
              pickup_list_zone_id: 0,
              drop_off_list_zone_id: 0,
              driving_list_zone_id: 0,
              detail_driving_location: '',
              is_driver_stay_overnight: false,
              driver_stay_overnight_price: 0,
            }))
          : [],
      },
    });
  };

  const durationRentDateHandler = (duration) => {
    setDurationRent(duration);
    setSelectedCar(undefined);
    setOrderData({
      ...orderData,
      order_detail: {
        ...orderData.order_detail,
        end_booking_date: format(addDays(startRentDate, duration - 1), 'yyyy-MM-dd').toString(),
        vehicle_id: '',
      },
    });

    if (dayDuration.length !== 0) {
      const copyDayDuration = [...dayDuration];
      const slicedCopyDayDuration = copyDayDuration.slice(0, duration);
      setDayDuration(slicedCopyDayDuration);
    }
  };

  const countOvertime = (startTime, endTime) => {
    const startHour = +startTime.split(':')[0];
    const startMinute = +startTime.split(':')[1];
    const endHour = +endTime.split(':')[0];
    const endMinute = +endTime.split(':')[1];
    const compareHour = endHour - startHour;
    let overtimeValue = 0;

    if (compareHour === 12) {
      if (startMinute === endMinute) {
        overtimeValue = 0;
      }

      if (startMinute < endMinute) {
        overtimeValue = 1;
      }
    }

    if (compareHour > 12) {
      let newOvertime = compareHour - 12;

      if (startMinute < endMinute) {
        newOvertime += 1;
      }

      overtimeValue = newOvertime;
    }
    return overtimeValue;
  };

  return (
    <>
      <span className="detail-sewa__detail-sewa-form__title">
        <h2>Detail Hari</h2>
      </span>

      <div className="with-driver-form">
        <div className="with-driver-form__form">
          <SelectFieldShuttle
            label="Lokasi Rental"
            htmlFor="lokasi-rental"
            className="with-driver-form__form__rental-location"
            data={rentalLocationData}
            value={rentalLocationId.id}
            onChange={changeRentalLocationHandler}
            placeholder="Lokasi Rental"
            searchFeature
            disable={isOrderConfirmation}
          />

          {/* TANGGAL MULAI & DURASI */}
          <div className="with-driver-form__form__date-duration">
            <SelectFieldDate
              label="Tanggal Mulai"
              htmlFor="tanggal-mulai"
              name="tanggal-mulai"
              handleDaySelect={(date) => {
                if (date) {
                  setStartRentDate(date);
                  setOrderData({
                    ...orderData,
                    order_detail: {
                      ...orderData.order_detail,
                      vehicle_id: '',
                      start_booking_date: format(date, 'yyyy-MM-dd').toString(),
                      end_booking_date: durationRent
                        ? format(addDays(date, durationRent - 1), 'yyyy-MM-dd').toString()
                        : '',
                    },
                  });
                  setSelectedCar(undefined);
                }
              }}
              selectedDay={startRentDate}
              value={startRentDate !== '' ? format(startRentDate, 'dd-MM-yyyy') : startRentDate}
              placeholder="Pilih Tanggal"
              disable={isOrderConfirmation}
            />

            <SelectFieldDuration
              label="Durasi Sewa"
              htmlFor="durasi-sewa"
              placeholder="0 Hari"
              startDate={startRentDate}
              value={durationRent}
              onClick={durationRentDateHandler}
              disable={isOrderConfirmation ? true : startRentDate ? false : true}
            />
          </div>
          {Array.from({ length: durationRent }, (_, i) => i + 1).map((_, idx) => {
            return (
              <div key={idx} className="with-driver-form__form__day">
                <div className="with-driver-form__form__time">
                  <InputField
                    disable={true}
                    label={`Day ${idx + 1}`}
                    icon={<Calendar />}
                    iconPosition="start"
                    value={(!isNaN(startRentDate) && format(addDays(startRentDate, idx), 'dd-MM-yyyy')) || ''}
                    readOnly
                  />
                  {/* OVERTIME */}
                  <SelectFieldDropdown
                    label="Tambahan Overtime"
                    htmlFor="tambahan-waktu-overtime"
                    value={dayDuration[idx]?.overtime_duration || ''}
                    data={overtimeWithDriver}
                    placeholder="Overtime"
                    disable={true}
                  />
                </div>

                {/* JAM MULAI & JAM SELESAI */}
                <div className="with-driver-form__form__time">
                  <SelectFieldTimeWithDriver
                    label="Jam Mulai"
                    htmlFor="jam-mulai"
                    placeholder="00:00"
                    value={dayDuration[idx]?.booking_start_time || ''}
                    onChange={(hour, minute) => {
                      if (idx == 0) {
                        setOrderData({
                          ...orderData,
                          order_detail: {
                            ...orderData.order_detail,
                            start_booking_time: `${hour}:${minute ? minute : '00'}`,
                            end_booking_time:
                              hour === 23
                                ? '23:30'
                                : +orderData.order_detail.end_booking_time?.split(':')[0] - hour === 12
                                ? `${orderData.order_detail.end_booking_time?.split(':')[0]}:${minute ? minute : '00'}`
                                : `${+hour + 12 > 23 ? 23 : +hour + 12}:${minute ? minute : '00'}`,
                          },
                        });
                      }
                      setDayDuration((prev) =>
                        prev[idx] === undefined
                          ? [
                              ...prev,
                              {
                                ...prev[idx],
                                booking_start_time: `${hour}:${minute ? minute : '00'}`,
                                booking_end_time: `${+hour + 12 > 23 ? 23 : +hour + 12}:${minute ? minute : '00'}`,
                                overtime_duration: 'ot0',
                              },
                            ]
                          : prev.map((item, day) =>
                              day === idx
                                ? {
                                    ...prev[idx],
                                    booking_start_time: `${hour}:${minute ? minute : '00'}`,
                                    booking_end_time:
                                      hour === 23
                                        ? '23:30'
                                        : // if end time - start time equal 12, set minute same with start time
                                        +prev[idx].booking_end_time?.split(':')[0] - hour === 12
                                        ? `${prev[idx].booking_end_time?.split(':')[0]}:${minute ? minute : '00'}`
                                        : // else end time not changed
                                          `${+hour + 12 > 23 ? 23 : +hour + 12}:${minute ? minute : '00'}`,
                                    overtime_duration: 'ot0',
                                  }
                                : item,
                            ),
                      );
                    }}
                    disable={isOrderConfirmation ? true : startRentDate === '' ? true : false}
                  />
                  <SelectFieldTimeWithDriver
                    label="Jam Selesai"
                    htmlFor="jam-selesai"
                    placeholder="00:00"
                    value={dayDuration[idx]?.booking_end_time || ''}
                    onChange={(hour, minute) => {
                      const overtimeValue = countOvertime(
                        dayDuration[idx]?.booking_start_time,
                        `${hour}:${minute ? minute : '00'}`,
                      );
                      if (idx == 0) {
                        setOrderData({
                          ...orderData,
                          order_detail: {
                            ...orderData.order_detail,
                            end_booking_time: `${hour}:${minute ? minute : '00'}`,
                          },
                        });
                      }
                      setDayDuration((prev) =>
                        prev[idx] === undefined
                          ? [
                              ...prev,
                              {
                                ...prev[idx],
                                booking_end_time: `${hour}:${minute ? minute : '00'}`,
                                overtime_duration:
                                  overtimeValue > 0
                                    ? overtimeWithDriver.find((ovt) => ovt.value === overtimeValue)?.id
                                    : 'ot0',
                              },
                            ]
                          : prev.map((item, day) =>
                              day === idx
                                ? {
                                    ...prev[idx],
                                    booking_end_time: `${hour}:${minute ? minute : '00'}`,
                                    overtime_duration:
                                      overtimeValue > 0
                                        ? overtimeWithDriver.find((ovt) => ovt.value === overtimeValue)?.id
                                        : 'ot0',
                                  }
                                : item,
                            ),
                      );
                    }}
                    disable={
                      isOrderConfirmation
                        ? true
                        : startRentDate === '' ||
                          dayDuration[idx]?.booking_start_time === '' ||
                          dayDuration[idx]?.booking_start_time === undefined
                        ? true
                        : false
                    }
                    startTime={dayDuration[idx]?.booking_start_time}
                  />
                </div>
              </div>
            );
          })}
        </div>

        {/* DETAIL SEWA */}
        <div className="with-driver-form__detail-sewa">
          <h2>Detail Sewa</h2>
          <div className="with-driver-form__detail-sewa-form">
            {/* VEHICLE */}
            <SelectFieldCar
              label="Pilih Mobil"
              htmlFor="pilih-mobil"
              placeholder="Cari Mobil.."
              data={allVehicle}
              selectedCar={selectedCar}
              onSelectCar={setSelectedCar}
              value={vehicleInput}
              // intersectionAction={intersectionAction}
              onChange={(e) => setVehicleInput(e.target.value)}
              onClick={(vehicleId, resetVehicle) => {
                if (vehicleId === '') {
                  setVehicleInput(resetVehicle);
                  return;
                }
                setOrderData({ ...orderData, order_detail: { ...orderData.order_detail, vehicle_id: vehicleId } });
                setVehicleInput(resetVehicle);
              }}
              payloadFilter={{
                locationRental: rentalLocationId?.id,
                startTrip: `${orderData.order_detail.start_booking_date} ${orderData.order_detail.start_booking_time}`,
                endTrip: `${orderData.order_detail.end_booking_date} ${orderData.order_detail.end_booking_time}`,
                supportDriver: !orderData.order_detail.without_driver,
                customOrder: true,
                page: 1,
                subServiceId: getSubserviceIdWithDriver(),
              }}
              disable={
                isOrderConfirmation
                  ? true
                  : orderData.order_detail.start_booking_date === '' ||
                    orderData.order_detail.end_booking_date === '' ||
                    dayDuration[0]?.booking_start_time === '' ||
                    dayDuration[0]?.booking_start_time === undefined
              }
            />

            {/* DEPOSIT & REFERAL CODE */}
            <div className="with-driver-form__form__deposit-refferal">
              <InputField
                type="number"
                label="Deposit (Opsional)"
                htmlFor="deposit"
                placeholder="Rp. 0"
                value={orderData.deposit || ''}
                onChange={(e) => setOrderData({ ...orderData, deposit: parseInt(e.target.value) })}
                disable={isOrderConfirmation}
                onWheel={numberInputOnWheelPreventChange}
              />
              {/* <InputField
            label="Kode Refferal (Opsional)"
            htmlFor="kode-efferal"
            placeholder="Masukan Kode Refferal"
            // value={refferalCode}
            // onChange={(e) => setRefferalCode(e.target.value)}
            disable={isOrderConfirmation}
          /> */}
            </div>

            {/* ADDITIONAL */}
            {/* <SelectFieldAdditional
          label="Pilih Additional"
          htmlFor="pilih-additional"
          placeholder="Pilih Additional"
          data={additional}
          disable={true}
        /> */}

            <div className="with-driver-form__form__payment-passenger">
              {/* PAYMENT TYPE */}
              <SelectFieldDropdown
                label="Tipe Pembayaran"
                htmlFor="tipe-pembayaran"
                value={selectedPaymentType}
                data={mappedPaymentType}
                onChange={(e) => {
                  setSelectedPaymentType(e.target.id);
                }}
                placeholder="Tipe Pembayaran"
                disable={
                  orderData.order_detail.start_booking_date === '' ||
                  orderData.order_detail.end_booking_date === '' ||
                  orderData.order_detail.start_booking_time === '' ||
                  orderData.order_detail.end_booking_time === '' ||
                  orderData.order_detail.vehicle_id === '' ||
                  isOrderConfirmation
                }
              />
              {/* PASSENGER SEAT */}
              <SelectFieldDropdown
                label="Jumlah Penumpang"
                htmlFor="pilih-jumlah-penumpang"
                placeholder="Pilih Jumlah Penumpang"
                icon={<CarChair />}
                data={
                  selectedCar
                    ? Array.from({ length: selectedCar?.max_passanger + 1 }, (_, idx) => idx + 1).map((item) => ({
                        id: item,
                        name: `${item} Orang`,
                      }))
                    : []
                }
                value={orderData.order_detail.passenger_number}
                onChange={(e) =>
                  setOrderData({
                    ...orderData,
                    order_detail: { ...orderData.order_detail, passenger_number: +e.target.id },
                  })
                }
                disable={!selectedCar || isOrderConfirmation}
              />
            </div>

            <div className="with-driver-form__form__baggage-passenger">
              <InputField label="Kapasitas Koper" value={selectedCar?.max_suitcase || 0} disable />
              <InputField
                type="number"
                label="Koper yang dibawa"
                htmlFor="baggage"
                placeholder="Tulis Jumlah Koper"
                value={orderData.order_detail.baggage}
                onChange={(e) =>
                  setOrderData({
                    ...orderData,
                    order_detail: {
                      ...orderData.order_detail,
                      baggage: isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value),
                    },
                  })
                }
                disable={isOrderConfirmation}
                onWheel={numberInputOnWheelPreventChange}
              />
            </div>
          </div>
        </div>

        {/* BOOKING ZONE */}
        <div className="with-driver-form__booking-zone">
          <h2>Zona Penyewaan</h2>
          <BookingZone
            durationRentDate={durationRent}
            startDate={startRentDate}
            orderData={orderData}
            setOrderData={setOrderData}
            selectedCar={selectedCar}
            isOrderConfirmation={isOrderConfirmation}
            allZonesList={allZonesList}
            dayDuration={dayDuration}
            forCustomOrder={true}
            rentalLocationId={rentalLocationId}
          />
        </div>

        {/* DIVER ASSIGN */}
        {/* <div className="with-driver-form__driver-assign">
        <SelectFieldDropdown
          label="Assign to Driver"
          htmlFor="assign-to-driver"
          data={courierData.data}
          placeholder="Pilih Driver"
          value={driver}
          onChange={(e) => setDriver(e.target.id)}
          disable={isOrderConfirmation}
        />
      </div> */}

        {/* ADDITIONAL ITEM */}
        <div className="with-driver-form__additional-item">
          <h2>Additional</h2>
          <div className="wrapper">
            <SelectFieldAdditionalItem
              label="Pilih Tambahan"
              id="tambahan"
              className="additional"
              placeholder="Cari Perlengkapan Khusus.."
              selectedAdditionalItem={selectedAdditionalItem}
              setSelectedAdditionalItem={setSelectedAdditionalItem}
              value={additionalSearchInput}
              onChange={(e) => setAdditionalSearchInput(e.target.value)}
              data={additionalData?.data || []}
              disable={isOrderConfirmation || !additionalData || checkEmptyObject(additionalData)}
              filterParams={{
                locationId: rentalLocationId.id,
                locTimeId: rentalLocationId.time_zone_identifier,
                startDate: orderData.order_detail.start_booking_date,
                endDate: orderData.order_detail.end_booking_date,
                startTime: orderData.order_detail.start_booking_time + ':00',
                endTime: orderData.order_detail.end_booking_time + ':00',
              }}
            />

            <InputField
              label="Total Biaya Permintaan Khusus"
              placeholder="Rp 0"
              value={summaryOrderData?.addon_total_price ? setCurrency(summaryOrderData.addon_total_price) : ''}
              disable
            />

            {/* <TextAreaInput
              label="Detail Tambahan"
              htmlFor="detail-tambahan"
              placeholder="Tulis Keterangan"
              value={descriptionAdditionalItem}
              onChange={(e) => setDescriptionAdditionalItem(e.target.value)}
              disable={isOrderConfirmation} 
            /> */}
          </div>
        </div>

        {/* ACTION BUTTON */}
        {!isOrderConfirmation && (
          <div className="with-driver-form__button-action">
            <div className="with-driver-form__button-action__action">
              <Button
                width="165px"
                height="39px"
                variant="outline"
                size="md"
                className="button"
                onClick={() => prevPage()}
                type="button"
              >
                Kembali
              </Button>
              <Button type="button" width="165px" height="39px" size="md" className="button" onClick={submitHandler}>
                Lanjutkan
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default WithDriverForm;
