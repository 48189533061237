import { useAppContext } from 'components/Context/AppContext';
import { Button, InputField, SelectFieldAdditionalItem } from 'components/Global';
import SelectFieldCar from 'components/Global/SelectFieldCar';
import SelectFieldDate from 'components/Global/SelectFieldDate';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';
import SelectFieldShuttle from 'components/Global/SelectFieldShuttle';
import SelectFieldTime from 'components/Global/SelectFieldTime';
import ViolationsList from 'components/Global/ViolationsList';
import { addDays, format, parseISO } from 'date-fns';
import { fetchSummaryOrder } from 'features/orders/actions';
// import { getVehiclesByFilter } from 'features/vehicle/action';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CarChair } from 'icons/car-chair-icon.svg';
// import TextAreaInput from 'components/Global/TextArea';
import { checkEmptyObject, setCurrency } from 'utils/functionality';

// OVERTIME
export const overtimeWithoutDriver = [
  {
    id: 'ot0',
    name: 'Tidak Ada Overtime',
    value: 0,
  },
  {
    id: 'ot1',
    name: '1 Jam',
    value: 1,
  },
  {
    id: 'ot2',
    name: '2 Jam',
    value: 2,
  },
  {
    id: 'ot3',
    name: '3 Jam',
    value: 3,
  },
  {
    id: 'ot4',
    name: '4 Jam',
    value: 4,
  },
  {
    id: 'ot5',
    name: '5 Jam',
    value: 5,
  },
  {
    id: 'ot6',
    name: '6 Jam',
    value: 6,
  },
  {
    id: 'ot7',
    name: '7 Jam',
    value: 7,
  },
  {
    id: 'ot8',
    name: '8 Jam',
    value: 8,
  },
  {
    id: 'ot9',
    name: '9 Jam',
    value: 9,
  },
  {
    id: 'ot10',
    name: '10 Jam',
    value: 10,
  },
  {
    id: 'ot11',
    name: '11 Jam',
    value: 11,
  },
  {
    id: 'ot12',
    name: '12 Jam',
    value: 12,
  },
  {
    id: 'ot13',
    name: '13 Jam',
    value: 13,
  },
  {
    id: 'ot14',
    name: '14 Jam',
    value: 14,
  },
  {
    id: 'ot15',
    name: '15 Jam',
    value: 15,
  },
  {
    id: 'ot16',
    name: '16 Jam',
    value: 16,
  },
  {
    id: 'ot17',
    name: '17 Jam',
    value: 17,
  },
  {
    id: 'ot18',
    name: '18 Jam',
    value: 18,
  },
  {
    id: 'ot19',
    name: '19 Jam',
    value: 19,
  },
  {
    id: 'ot20',
    name: '20 Jam',
    value: 20,
  },
  {
    id: 'ot21',
    name: '21 Jam',
    value: 21,
  },
  {
    id: 'ot22',
    name: '22 Jam',
    value: 22,
  },
  {
    id: 'ot23',
    name: '23 Jam',
    value: 23,
  },
  {
    id: 'ot24',
    name: '24 Jam',
    value: 24,
  },
];

// PAYMENT TYPE
const paymentType = [
  {
    id: 'pyt1',
    name: 'Pembayaran Full',
    value: 'FULL',
  },
  {
    id: 'pyt2',
    name: 'Pembayaran DP',
    value: 'HALF',
  },
];

const WithoutDriverForm = ({
  prevPage,
  nextPage,
  orderData,
  setOrderData,
  vehicleInput,
  setVehicleInput,
  selectedCar,
  setSelectedCar,
  startRentDate,
  setStartRentDate,
  endRentDate,
  setEndRentDate,
  deliveryLocation,
  setDeliveryLocation,
  returnLocation,
  setReturnLocation,
  isOrderConfirmation,
  // refferalCode,
  // setRefferalCode,
  // driver,
  // setDriver,
  allVehicle,
  selectedOvertime,
  setSelectedOvertime,
  selectedPaymentType,
  setSelectedPaymentType,
  rentalLocationId,
  setRentalLocationId,
  selectedAdditionalItem,
  setSelectedAdditionalItem,
  // descriptionAdditionalItem,
  // setDescriptionAdditionalItem,
}) => {
  const dispatch = useDispatch();

  // CONTEXT
  const { showToast } = useAppContext();

  // FIRST LOAD STATE
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  // MAPPED LOCAL STATE
  const [mappedPaymentType, setMappedPaymentType] = useState(paymentType);

  // ADDITIONAL INPUT SEARCH VALUE
  const [additionalSearchInput, setAdditionalSearchInput] = useState('');

  // GLOBAL STATE
  const { data: dataDelliveryLocation } = useSelector((state) => state.deliveryLocation);
  // const { data: dataVehicle } = useSelector((state) => state.vehicle);
  const { data: violationsData } = useSelector((state) => state.violations);
  // const { data: courierData } = useSelector((state) => state.couriers);
  const { data: rentalLocationData } = useSelector((state) => state.rentalLocation);
  const { data: rentalServicesData } = useSelector((state) => state.services);
  const { data: summaryOrderData } = useSelector((state) => state.summaryOrder);
  const { data: additionalData } = useSelector((state) => state.addon);

  // FUNCTION TO PREVENT SCROLLING ON INPUT NUMBER TYPE
  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current
    // function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  // SCROLL TO TOP WHEN COMPONENT IS LOAD
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getSubserviceIdWithoutDriver = () => {
    const subServiceWithoutDriver = rentalServicesData
      ?.find((item) => item.name === 'Sewa Mobil')
      ?.sub_services?.find((item) => item.name === 'Daily')
      ?.facilities?.find((item) => item.name === 'Without Driver');

    return subServiceWithoutDriver?.id;
  };

  // WILL SET THE END TIME BOOKING PROGRAMMATICALY
  // useEffect(() => {
  //   if (orderData.order_detail.start_booking_time === '') return;
  //   setOrderData({
  //     ...orderData,
  //     order_detail: { ...orderData.order_detail, end_booking_time: '' },
  //   });
  // }, [orderData.order_detail.start_booking_time]);

  // WILL RESET THE END DATE BOOKING WHEN START DATE BOOKING CHANGING
  useEffect(() => {
    if (orderData.order_detail.start_booking_date === '' || isFirstLoad) return;
    setOrderData({
      ...orderData,
      order_detail: { ...orderData.order_detail, end_booking_date: '' },
    });
  }, [orderData.order_detail.start_booking_date]);

  // WILL SET VIOLATIONS WHEN VIOLATIONS GLOBAL STATE CHANGING
  useEffect(() => {
    if (Object.keys(violationsData).length === 0) return;

    setOrderData({
      ...orderData,
      order_violations: violationsData.violations.map((item) => {
        return { id: item.id, violation: item.violation, cost: item.fee };
      }),
    });
  }, [violationsData]);

  // OVERTIME
  useEffect(() => {
    if (!selectedOvertime) return;

    const overtimeValue = overtimeWithoutDriver.find((item) => item.id === selectedOvertime).value;
    setOrderData({ ...orderData, over_time: overtimeValue });
  }, [selectedOvertime]);

  // PAYMENT TYPE
  useEffect(() => {
    if (!selectedPaymentType) return;

    const paymentTypeValue = paymentType.find((item) => item.id === selectedPaymentType).value;
    setOrderData({ ...orderData, type: paymentTypeValue });
  }, [selectedPaymentType]);

  useEffect(() => {
    if (
      orderData.order_detail.start_booking_date === '' ||
      orderData.order_detail.start_booking_time === '' ||
      orderData.order_detail.end_booking_date === '' ||
      orderData.order_detail.end_booking_time === '' ||
      !orderData.order_detail.vehicle_id ||
      isOrderConfirmation
    )
      return;

    let mappingAdditionalItem = [];

    if (selectedAdditionalItem) {
      for (let addItionalChild of selectedAdditionalItem) {
        const additionalObj = {
          id: addItionalChild.additional.id,
          varieties: [
            {
              id: addItionalChild.additional.varieties[0].id,
              quantity: addItionalChild.total,
            },
          ],
        };

        mappingAdditionalItem.push(additionalObj);
      }
    }

    dispatch(
      fetchSummaryOrder({
        end_booking_date: orderData.order_detail.end_booking_date,
        end_booking_time: orderData.order_detail.end_booking_time,
        start_booking_date: orderData.order_detail.start_booking_date,
        start_booking_time: orderData.order_detail.start_booking_time,
        order_type_id: orderData.order_type_id,
        vehicle_id: orderData.order_detail.vehicle_id,
        without_driver: orderData.order_detail.without_driver ? 1 : 0,
        rental_return_id: dataDelliveryLocation?.shuttle?.find(
          (item) => item.name === orderData.order_detail.rental_return_location,
        ).id,
        rental_delivery_id: dataDelliveryLocation?.shuttle?.find(
          (item) => item.name === orderData.order_detail.rental_delivery_location,
        ).id,
        location_id: rentalLocationId?.id,
        sub_services_id: getSubserviceIdWithoutDriver(),
        addons: mappingAdditionalItem.length === 0 ? undefined : mappingAdditionalItem,
      }),
    );
  }, [
    rentalServicesData,
    orderData.order_detail.end_booking_date,
    orderData.order_detail.end_booking_time,
    orderData.order_detail.start_booking_date,
    orderData.order_detail.start_booking_time,
    orderData.order_detail.vehicle_id,
    dataDelliveryLocation,
    selectedAdditionalItem,
  ]);

  useEffect(() => {
    if (!Object.keys(summaryOrderData).length) return;

    if (summaryOrderData.total_dp > 0) {
      setMappedPaymentType((prev) =>
        prev.map((item) =>
          item.id == 'pyt2'
            ? {
                ...item,
                name: `${
                  item.name.includes('%')
                    ? item.name
                    : summaryOrderData?.formula_percentage?.value
                    ? `${item.name} ${summaryOrderData.formula_percentage.value}%`
                    : item.name
                }`,
              }
            : item,
        ),
      );
    } else if (summaryOrderData.total_dp === 0) {
      setMappedPaymentType((prev) => prev.filter((item) => item.id !== 'pyt2'));
      setSelectedPaymentType('pyt1');
    }
  }, [summaryOrderData]);

  // INTERSECTION FUNCTION OF VEHICLES
  // const intersectionAction = () => {
  //   if (orderData.order_detail.vehicle_id !== '') return;

  //   const payload = {
  //     locationRental: rentalLocationId?.id,
  //     startTrip: `${orderData.order_detail.start_booking_date} ${orderData.order_detail.start_booking_time}`,
  //     endTrip: `${orderData.order_detail.end_booking_date} ${orderData.order_detail.end_booking_time}`,
  //     supportDriver: !orderData.order_detail.without_driver,
  //     customOrder: true,
  //     page: dataVehicle.pagination.page + 1,
  //     limit: 0,
  //   };

  //   // if last page then just return nothing
  //   if (dataVehicle.pagination.page >= dataVehicle.pagination.last_page) return;

  //   // if not last page fetch
  //   dispatch(getVehiclesByFilter(payload));
  // };

  // SUBMIT HANDLER FUNCTION
  const submitHandler = async (e) => {
    e.preventDefault();

    // some validation
    const validStartBookingDate = orderData.order_detail.start_booking_date !== '';
    const validEndBookingDate = orderData.order_detail.end_booking_date !== '';
    const validStartBookingTime = orderData.order_detail.start_booking_time !== '';
    const validEndBookingTime = orderData.order_detail.end_booking_time !== '';
    const validDeliveryLocation = orderData.order_detail.rental_delivery_location !== '';
    const validReturnLocation = orderData.order_detail.rental_return_location !== '';
    const validVehicle = orderData.order_detail.vehicle_id !== '';
    const validDetailDeliveryLocation = orderData.order_detail.rental_delivery_location_detail !== '';
    const validReturnDeliveryLocation = orderData.order_detail.rental_return_location_detail !== '';
    const validFlightNumber = orderData.order_detail.flight_number !== '';
    const validLandingTime = orderData.order_detail.landing_time !== '';
    // const validDriver = driver !== 0;
    const validPaymentType = orderData.type !== '';
    const validBaggage = isNaN(orderData.order_detail.baggage) || orderData.order_detail.baggage === '';
    const validPassenger = orderData.order_detail.passenger_number;

    // check if all input are valid
    if (!validDeliveryLocation) {
      return showToast({ type: 'error', message: 'Pilih Lokasi Pengantaran' });
    } else if (!validReturnLocation) {
      return showToast({ type: 'error', message: 'Pilih Lokasi Pengembalian' });
    } else if (!validDetailDeliveryLocation) {
      return showToast({ type: 'error', message: 'Masukan Detail Lokasi Pengantaran' });
    } else if (!validReturnDeliveryLocation) {
      return showToast({ type: 'error', message: 'Masukan Detail Lokasi Pengembalian' });
    } else if (!validStartBookingDate) {
      return showToast({ type: 'error', message: 'Pilih Tanggal Mulai' });
    } else if (!validEndBookingDate) {
      return showToast({ type: 'error', message: 'Pilih Tanggal Selesai' });
    } else if (!validStartBookingTime) {
      return showToast({ type: 'error', message: 'Pilih Jam Mulai' });
    } else if (!validEndBookingTime) {
      return showToast({ type: 'error', message: 'Pilih Jam Selesai' });
    } else if (!validVehicle) {
      return showToast({ type: 'error', message: 'Pilih Mobil' });
    } else if (!validPaymentType) {
      return showToast({ type: 'error', message: 'Pilih Tipe Pembayaran' });
    } else if (!validPassenger) {
      return showToast({ type: 'error', message: 'Pilih Jumlah Penumpang' });
    } else if (validBaggage) {
      return showToast({ type: 'error', message: 'Masukan Banyak Koper yang dibawa' });
    }
    // else if (!validDriver) {
    //   return showToast({ type: 'error', message: 'Pilih Driver' });
    // }

    if (deliveryLocation.airport) {
      if (!validFlightNumber) {
        return showToast({ type: 'error', message: 'Masukan Nomor Penerbangan' });
      } else if (!validLandingTime) {
        return showToast({ type: 'error', message: 'Masukan Jam Landing' });
      }
    }

    const subServiceWithoutDriver = rentalServicesData
      ?.find((item) => item.name === 'Sewa Mobil')
      ?.sub_services?.find((item) => item.name === 'Daily')
      ?.facilities?.find((item) => item.name === 'Without Driver');

    let mappingAdditionalItem = [];

    if (selectedAdditionalItem) {
      for (let addItionalChild of selectedAdditionalItem) {
        const additionalObj = {
          id: addItionalChild.additional.id,
          varieties: [
            {
              id: addItionalChild.additional.varieties[0].id,
              quantity: addItionalChild.total,
            },
          ],
        };

        mappingAdditionalItem.push(additionalObj);
      }
    }

    // GET ORDER SUMMARY
    const filter = {
      end_booking_date: orderData.order_detail.end_booking_date,
      end_booking_time: orderData.order_detail.end_booking_time,
      start_booking_date: orderData.order_detail.start_booking_date,
      start_booking_time: orderData.order_detail.start_booking_time,
      order_type_id: orderData.order_type_id,
      vehicle_id: orderData.order_detail.vehicle_id,
      without_driver: orderData.order_detail.without_driver ? 1 : 0,
      rental_return_id: dataDelliveryLocation?.shuttle.find(
        (item) => item.name === orderData.order_detail.rental_return_location,
      ).id,
      rental_delivery_id: dataDelliveryLocation?.shuttle.find(
        (item) => item.name === orderData.order_detail.rental_delivery_location,
      ).id,
      deposit: orderData.deposit,
      deposit_e_toll: orderData.deposit_e_toll,
      violations: orderData.order_violations.reduce((acc, cur) => acc + cur.cost, 0),
      overtime: overtimeWithoutDriver.find((ovt) => ovt.id === orderData.over_time)?.value || 0,
      location_id: rentalLocationId?.id,
      sub_services_id: subServiceWithoutDriver?.id,
      pasengger_number: orderData.order_detail.passenger_number,
      addons: mappingAdditionalItem.length === 0 ? undefined : mappingAdditionalItem,
    };

    try {
      const response = await dispatch(fetchSummaryOrder(filter));
      if (response.meta?.requestStatus === 'fulfilled') {
        nextPage();
        return;
      }
      showToast({ type: 'error', message: 'Terjadi Kesalahan' });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      showToast({ type: 'error', message: 'Terjadi Kesalahan' });
    }
  };

  const countOvertime = (startTime, endTime) => {
    const startHour = +startTime.split(':')[0];
    const startMinute = +startTime.split(':')[1];
    const endHour = +endTime.split(':')[0];
    const endMinute = +endTime.split(':')[1];

    let overtimeValue = 0;

    if (startHour === endHour) {
      if (startMinute === endMinute) {
        overtimeValue = 0;
      }

      if (startMinute < endMinute) {
        overtimeValue = 1;
      }
    }

    if (startHour < endHour) {
      let newOvertime = endHour - startHour;

      if (startMinute < endMinute) {
        newOvertime += 1;
      }

      overtimeValue = newOvertime;
    }

    return overtimeValue;
  };

  return (
    <div className="without-driver-form">
      <SelectFieldShuttle
        label="Lokasi Rental"
        htmlFor="lokasi-rental"
        className="without-driver-form__rental-location"
        data={rentalLocationData}
        value={rentalLocationId.id}
        onChange={(item) => {
          setRentalLocationId(item);
          setSelectedAdditionalItem(undefined);
        }}
        placeholder="Lokasi Rental"
        searchFeature
        disable={isOrderConfirmation}
      />

      <div className="without-driver-form__form">
        {/* DELIVERY LOCATION */}
        <SelectFieldShuttle
          label="Lokasi Pengantaran"
          htmlFor="lokasi-pengantaran"
          value={deliveryLocation.id}
          data={dataDelliveryLocation.shuttle}
          onChange={(item) => {
            setDeliveryLocation(item);
            setOrderData({
              ...orderData,
              rental_delivery_fee: item.fee,
              order_detail: { ...orderData.order_detail, rental_delivery_location: item.name },
            });
          }}
          placeholder="Masukan Lokasi Pengantaran"
          disable={!rentalLocationId.id ? true : isOrderConfirmation ? true : false}
          searchFeature
        />

        {/* RETURN LOCATION */}
        <SelectFieldShuttle
          label="Lokasi Pengembalian"
          htmlFor="lokasi-pengembalian"
          value={returnLocation.id}
          data={dataDelliveryLocation.shuttle}
          onChange={(item) => {
            setReturnLocation(item);
            setOrderData({
              ...orderData,
              rental_return_fee: item.fee,
              order_detail: { ...orderData.order_detail, rental_return_location: item.name },
            });
          }}
          placeholder="Masukan Lokasi Pengembalian"
          disable={!rentalLocationId.id ? true : isOrderConfirmation ? true : false}
          searchFeature
        />

        {/* DETAIL DELIVERY LOCATION */}
        {!deliveryLocation?.airport ? (
          <InputField
            label="Detail Lokasi Pengantaran"
            htmlFor="detail-lokasi-pengantaran"
            placeholder="Tulis detail lokasi"
            value={orderData.order_detail.rental_delivery_location_detail}
            onChange={(e) =>
              setOrderData({
                ...orderData,
                order_detail: { ...orderData.order_detail, rental_delivery_location_detail: e.target.value },
              })
            }
            disable={isOrderConfirmation}
          />
        ) : (
          <div className="without-driver-form__form__jadwal-penerbangan">
            <InputField
              label="No. Penerbangan"
              htmlFor="no-penerbangan"
              placeholder="Tulis No. Penerbangan"
              value={orderData.order_detail.flight_number}
              onChange={(e) =>
                setOrderData({
                  ...orderData,
                  order_detail: { ...orderData.order_detail, flight_number: e.target.value },
                })
              }
              disable={isOrderConfirmation}
            />
            <SelectFieldTime
              label="Jam Landing"
              htmlFor="jam-landing"
              placeholder="00:00"
              value={orderData.order_detail.landing_time}
              onChange={(hour, minute) => {
                setOrderData({
                  ...orderData,
                  order_detail: { ...orderData.order_detail, landing_time: `${hour}:${minute ? minute : '00'}` },
                });
              }}
              disable={isOrderConfirmation}
              showAllHours={true}
            />
            <InputField
              label="Detail Lokasi"
              htmlFor="detail-lokasi-pengantaran"
              placeholder="Tulis detail lokasi"
              value={orderData.order_detail.rental_delivery_location_detail}
              onChange={(e) =>
                setOrderData({
                  ...orderData,
                  order_detail: { ...orderData.order_detail, rental_delivery_location_detail: e.target.value },
                })
              }
              style={{ alignItems: 'start' }}
              disable={isOrderConfirmation}
            />
          </div>
        )}

        {/* DETAIL RETURN LOCATION */}
        <InputField
          label="Detail Lokasi Pengembalian"
          htmlFor="detail-lokasi-pengembalian"
          placeholder="Tulis detail lokasi"
          value={orderData.order_detail.rental_return_location_detail}
          onChange={(e) =>
            setOrderData({
              ...orderData,
              order_detail: { ...orderData.order_detail, rental_return_location_detail: e.target.value },
            })
          }
          disable={isOrderConfirmation}
        />

        {/* START DATE & END DATE */}
        <div>
          {isOrderConfirmation ? (
            <SelectFieldDate
              label="Tanggal Mulai"
              name="tanggal-mulai"
              selectedDay={startRentDate}
              value={startRentDate !== '' ? format(startRentDate, 'dd-MM-yyyy') : startRentDate}
              placeholder="Pilih Tanggal"
              disable
            />
          ) : (
            <SelectFieldDate
              label="Tanggal Mulai"
              htmlFor="tanggal-mulai"
              name="tanggal-mulai"
              handleDaySelect={(date) => {
                if (date) {
                  setIsFirstLoad(false);
                  setStartRentDate(date);
                  setEndRentDate('');
                  setOrderData({
                    ...orderData,
                    order_detail: {
                      ...orderData.order_detail,
                      start_booking_date: format(date, 'yyyy-MM-dd').toString(),
                      vehicle_id: '',
                    },
                  });
                  setSelectedCar(undefined);
                  setVehicleInput('');
                }
              }}
              selectedDay={startRentDate}
              value={startRentDate !== '' ? format(startRentDate, 'dd-MM-yyyy') : startRentDate}
              placeholder="Pilih Tanggal"
              disable={isOrderConfirmation}
            />
          )}

          {isOrderConfirmation ? (
            <SelectFieldDate
              label="Tanggal Selesai"
              name="tanggal-selesai"
              selectedDay={endRentDate}
              fromDate={addDays(startRentDate === '' ? parseISO(startRentDate) : startRentDate, 1)}
              value={endRentDate !== '' ? format(endRentDate, 'dd-MM-yyyy') : endRentDate}
              placeholder="Pilih Tanggal"
              disable
            />
          ) : (
            <SelectFieldDate
              label="Tanggal Selesai"
              htmlFor="tanggal-selesai"
              name="tanggal-selesai"
              handleDaySelect={(date) => {
                if (date) {
                  setIsFirstLoad(false);
                  setEndRentDate(date);
                  setOrderData({
                    ...orderData,
                    order_detail: {
                      ...orderData.order_detail,
                      end_booking_date: format(date, 'yyyy-MM-dd').toString(),
                      vehicle_id: '',
                    },
                  });
                  setSelectedCar(undefined);
                  setVehicleInput('');
                }
              }}
              selectedDay={endRentDate}
              fromDate={addDays(startRentDate === '' ? parseISO(startRentDate) : startRentDate, 1)}
              value={endRentDate !== '' ? format(endRentDate, 'dd-MM-yyyy') : endRentDate}
              placeholder="Pilih Tanggal"
              disable={isOrderConfirmation ? true : startRentDate === '' ? true : false}
            />
          )}
        </div>

        {/* START TIME & END TIME */}
        <div>
          {isOrderConfirmation ? (
            <SelectFieldTime
              label="Jam Mulai"
              placeholder="00:00"
              value={orderData.order_detail.start_booking_time}
              disable
            />
          ) : (
            <SelectFieldTime
              label="Jam Mulai"
              htmlFor="jam-mulai"
              placeholder="00:00"
              value={orderData.order_detail.start_booking_time}
              onChange={(hour, minute) => {
                setOrderData({
                  ...orderData,
                  order_detail: {
                    ...orderData.order_detail,
                    start_booking_time: `${hour}:${minute ? minute : '00'}`,
                    end_booking_time: `${hour}:${minute ? minute : '00'}`,
                    vehicle_id: '',
                  },
                  over_time: 'ot0',
                });
                setSelectedOvertime('');
                setSelectedCar(undefined);
                setVehicleInput('');
              }}
              // choosenDate={startRentDate}
              disable={isOrderConfirmation ? true : startRentDate === '' ? true : false}
              showAllHours={true}
            />
          )}
          {isOrderConfirmation ? (
            <SelectFieldTime
              label="Jam Selesai"
              placeholder="00:00"
              value={orderData.order_detail.end_booking_time}
              disable
              startHour={orderData.order_detail.start_booking_time}
              // showAllHours={true}
              variantHours="WITHOUT_DRIVER"
            />
          ) : (
            <SelectFieldTime
              label="Jam Selesai"
              htmlFor="jam-selesai"
              placeholder="00:00"
              value={orderData.order_detail.end_booking_time}
              onChange={(hour, minute) => {
                const overtimeValue = countOvertime(
                  orderData.order_detail.start_booking_time,
                  `${hour}:${minute ? minute : '00'}`,
                );
                setOrderData({
                  ...orderData,
                  order_detail: { ...orderData.order_detail, end_booking_time: `${hour}:${minute ? minute : '00'}` },
                  over_time:
                    overtimeValue > 0 ? overtimeWithoutDriver.find((ovt) => ovt.value === overtimeValue)?.id : 'ot0',
                  vehicle_id: '',
                });
                setSelectedCar(undefined);
                setVehicleInput('');
              }}
              disable={
                isOrderConfirmation
                  ? true
                  : startRentDate === '' || orderData.order_detail.start_booking_time === ''
                  ? true
                  : false
              }
              startHour={orderData.order_detail.start_booking_time}
              showAllHours={true}
              // variantHours="WITHOUT_DRIVER"
            />
          )}
        </div>

        {/* VEHICLE */}
        <SelectFieldCar
          label="Pilih Mobil"
          htmlFor="pilih-mobil"
          placeholder="Cari Mobil.."
          data={allVehicle}
          selectedCar={selectedCar}
          onSelectCar={setSelectedCar}
          value={vehicleInput}
          // intersectionAction={intersectionAction}
          onChange={(e) => setVehicleInput(e.target.value)}
          onClick={(vehicleId, resetVehicle) => {
            if (vehicleId === '') {
              setVehicleInput(resetVehicle);
              return;
            }
            setOrderData({ ...orderData, order_detail: { ...orderData.order_detail, vehicle_id: vehicleId } });
            setVehicleInput(resetVehicle);
          }}
          payloadFilter={{
            locationRental: rentalLocationId?.id,
            startTrip: `${orderData.order_detail.start_booking_date} ${orderData.order_detail.start_booking_time}`,
            endTrip: `${orderData.order_detail.end_booking_date} ${orderData.order_detail.end_booking_time}`,
            supportDriver: !orderData.order_detail.without_driver,
            customOrder: true,
            page: 0,
            limit: 0,
            subServiceId: getSubserviceIdWithoutDriver(),
          }}
          disable={
            isOrderConfirmation
              ? true
              : orderData.order_detail.start_booking_date === '' ||
                orderData.order_detail.end_booking_date === '' ||
                orderData.order_detail.start_booking_time === '' ||
                orderData.order_detail.end_booking_time === ''
          }
        />

        {/* TIPE PEMBAYARAN */}
        {isOrderConfirmation ? (
          <SelectFieldDropdown
            label="Tipe Pembayaran"
            value={selectedPaymentType}
            data={mappedPaymentType}
            placeholder="Tipe Pembayaran"
            disable
          />
        ) : (
          <SelectFieldDropdown
            label="Tipe Pembayaran"
            htmlFor="tipe-pembayaran"
            value={selectedPaymentType}
            data={mappedPaymentType}
            onChange={(e) => {
              setSelectedPaymentType(e.target.id);
            }}
            placeholder="Tipe Pembayaran"
            disable={
              orderData.order_detail.start_booking_date === '' ||
              orderData.order_detail.end_booking_date === '' ||
              orderData.order_detail.start_booking_time === '' ||
              orderData.order_detail.end_booking_time === '' ||
              orderData.order_detail.vehicle_id === '' ||
              isOrderConfirmation
            }
          />
        )}

        <div>
          {/* OVERTIME */}
          <SelectFieldDropdown
            label="Tambahan Overtime"
            value={orderData?.over_time}
            data={overtimeWithoutDriver}
            placeholder="Overtime"
            disable={true}
          />

          {/* PASSENGER SEAT */}
          <SelectFieldDropdown
            label="Jumlah Penumpang"
            htmlFor="pilih-jumlah-penumpang"
            placeholder="Pilih Jumlah Penumpang"
            icon={<CarChair />}
            data={
              selectedCar
                ? Array.from({ length: selectedCar?.max_passanger + 1 }, (_, idx) => idx + 1).map((item) => ({
                    id: item,
                    name: `${item} Orang`,
                  }))
                : []
            }
            value={orderData.order_detail.passenger_number}
            onChange={(e) =>
              setOrderData({
                ...orderData,
                order_detail: { ...orderData.order_detail, passenger_number: +e.target.id },
              })
            }
            disable={!selectedCar || isOrderConfirmation}
          />
        </div>

        {/* DEPOSIT & REFERAL CODE */}
        <div>
          <InputField
            type="number"
            label="Deposit (Opsional)"
            htmlFor="deposit"
            placeholder="Rp. 0"
            value={orderData.deposit || ''}
            onChange={(e) => setOrderData({ ...orderData, deposit: parseInt(e.target.value) })}
            disable={isOrderConfirmation}
            onWheel={numberInputOnWheelPreventChange}
          />
          <InputField
            type="number"
            label="Deposit e-Toll"
            htmlFor="deposit-e-toll"
            placeholder="Rp. 0"
            value={orderData.deposit_e_toll || ''}
            onChange={(e) => setOrderData({ ...orderData, deposit_e_toll: parseInt(e.target.value) })}
            disable={isOrderConfirmation}
            onWheel={numberInputOnWheelPreventChange}
          />
          {/* <InputField
        label="Kode Refferal (Opsional)"
        htmlFor="kode-efferal"
        placeholder="Masukan Kode Refferal"
        value={refferalCode}
        onChange={(e) => setRefferalCode(e.target.value)}
        disable={isOrderConfirmation}
      /> */}
        </div>

        <div>
          <InputField label="Kapasitas Koper" value={selectedCar?.max_suitcase || 0} disable />
          <InputField
            type="number"
            label="Koper yang dibawa"
            htmlFor="baggage"
            placeholder="Tulis Jumlah Koper"
            value={orderData.order_detail.baggage}
            onChange={(e) =>
              setOrderData({
                ...orderData,
                order_detail: {
                  ...orderData.order_detail,
                  baggage: isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value),
                },
              })
            }
            disable={isOrderConfirmation}
            onWheel={numberInputOnWheelPreventChange}
          />
        </div>
      </div>

      {/* VIOLATIONS */}
      <span className="without-driver-form__title">
        <h2>Pelanggaran</h2>
      </span>
      <ViolationsList data={orderData.order_violations} disable={isOrderConfirmation} />

      {/* ADDITIONAL ITEM */}
      <div className="without-driver-form__additional-item">
        <h2>Additional</h2>
        <div className="wrapper">
          <SelectFieldAdditionalItem
            label="Pilih Tambahan"
            id="tambahan"
            className="additional"
            placeholder="Cari Perlengkapan Khusus.."
            selectedAdditionalItem={selectedAdditionalItem}
            setSelectedAdditionalItem={setSelectedAdditionalItem}
            value={additionalSearchInput}
            onChange={(e) => setAdditionalSearchInput(e.target.value)}
            data={additionalData?.data || []}
            disable={isOrderConfirmation || !additionalData || checkEmptyObject(additionalData)}
            filterParams={{
              locationId: rentalLocationId?.id,
              locTimeId: rentalLocationId?.time_zone_identifier,
              startDate: orderData.order_detail?.start_booking_date,
              endDate: orderData.order_detail?.end_booking_date,
              startTime: orderData.order_detail?.start_booking_time + ':00',
              endTime: orderData.order_detail?.end_booking_time + ':00',
            }}
          />

          <InputField
            label="Total Biaya Permintaan Khusus"
            placeholder="Rp 0"
            value={summaryOrderData?.addon_total_price ? setCurrency(summaryOrderData.addon_total_price) : ''}
            disable
          />

          {/* <TextAreaInput
            label="Detail Tambahan"
            htmlFor="detail-tambahan"
            placeholder="Tulis Keterangan"
            value={descriptionAdditionalItem}
            onChange={(e) => setDescriptionAdditionalItem(e.target.value)}
            disable={isOrderConfirmation}
          /> */}
        </div>
      </div>

      {/* DIVER ASSIGN */}
      {/* <div className="without-driver-form__driver-assign">
        <SelectFieldDropdown
          label="Assign to Driver"
          htmlFor="assign-to-driver"
          data={courierData.data}
          placeholder="Pilih Driver"
          value={driver}
          onChange={(e) => setDriver(e.target.id)}
          disable={isOrderConfirmation}
        />
      </div> */}

      {/* ACTION BUTTON */}
      {!isOrderConfirmation && (
        <div className="without-driver-form__button-action">
          <div className="without-driver-form__button-action__action">
            <Button
              width="165px"
              height="39px"
              variant="outline"
              size="md"
              className="button"
              onClick={() => prevPage()}
              type="button"
            >
              Kembali
            </Button>
            <Button type="button" width="165px" height="39px" size="md" className="button" onClick={submitHandler}>
              Lanjutkan
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default WithoutDriverForm;
